import React, { Component } from 'react';
import PropTypes from 'prop-types';
// import { FontAWesomeIcon } from '@fortawesome/react-fontawesome';
import { AuthorizerHeader } from './CardView.jsx';
import { School } from './SchoolView.jsx';
import Infographic from './InfoGraphic.jsx';
import { groupBy } from '../lib/utils.js';
import logo from '../logo.png';

class PrintableListing extends Component {
	getSortedData() {
		const { data } = this.props;

		// first sort by school name
		let sortedData = data.sort((a, b) => a.charterSchoolName.localeCompare(b.charterSchoolName));

		// now group by authorizer
		sortedData = groupBy(sortedData, 'authorizer');
		return sortedData;
	}

	renderGroupedBy(data) {
		const GroupComponent = AuthorizerHeader;
		const rendered = [];

		// ensure keys are alphabetically sorted
		const keys = Object.keys(data).sort((a, b) => a.localeCompare(b, undefined, { numeric: true, sensitivity: 'base' }));

		keys.forEach((group) => {
			/* eslint-disable arrow-body-style */
			const schools = data[group].map((school, i) => {
				return <School key={school.charterSchoolName} school={school} hideAuthorizer />;
			});
			/* eslint-enable arrow-body-style */

			// first add header
			rendered.push([
				<div key={data[group][0].authorizer}>
					<div className="header-group-printable">
						<GroupComponent data={data[group][0]} />
						{schools[0]}
					</div>
					<div>
						{schools.slice(1)}
					</div>
				</div>,
			]);
		});

		return rendered;
	}

	render() {
		const data = this.getSortedData();

		return (
			<div className="printable">
				<div className="title-page">
					<div className="vertical-center">
						<img src={logo} className="site-logo" alt="logo" />
						<h1 className="App-title">Yearbook {new Date().getFullYear()}</h1>
					</div>
				</div>
				<div className="page-break" />
				<div style={{ marginTop: '20px', zoom: '80%' }}>
					<Infographic data={this.props.data} />
				</div>
				<div className="page-break" />
				{this.renderGroupedBy(data)}
			</div>
		);
	}
}

PrintableListing.propTypes = {
	data: PropTypes.array.isRequired,
};

export default PrintableListing;
