import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import ReactGA from 'react-ga';
import qs from 'qs';
import './lib/FontAwesome.js';
import Loader from './components/Loader.jsx';
import Header from './components/Header.jsx';
import Footer from './components/Footer.jsx';
import SchoolView from './components/SchoolView.jsx';
import Listing from './components/Listing.jsx';
import InfoGraphic from './components/InfoGraphic.jsx';
import PrintableListing from './components/PrintableListing.jsx';
import VideoListing from './components/VideoListing.jsx';
import { mergeGoveranceData, handleBasename } from './lib/utils.js';
import TrackPageView from './components/TrackPageView.jsx';
import { CredentialData } from './components/Credentials.jsx';

const DATA_URL = 'https://script.google.com/macros/s/AKfycbyOVg2fc_1eXk2qJYSMnvL_caVUqXF4GjeX96ChoyycHFyTfOKb5sPxIw-j2M7lrlaOFQ/exec';

// Initialize tracking
const GA_TRACKING_ID = 'UA-121996144-2';
ReactGA.initialize(GA_TRACKING_ID);

class App extends Component {
	constructor(props) {
		super(props);

		this.state = {
			data: null,
		};
	}

	componentDidMount() {
		this.fetchData();
	}

	fetchData() {
		const cache = JSON.parse(localStorage.getItem('cache'));
		const timestamp = new Date();

		// look for refreshData in querystring
		const options = qs.parse(window.location.search, { ignoreQueryPrefix: true });

		// only fetch data once per hour
		if (
			options.refreshData ||
			!cache ||
			timestamp.getTime() - cache.lastSaved > 3600000
		) {
			window.fetch(DATA_URL)
				.then(response => response.json())
				.then((data) => {
					const processedData = mergeGoveranceData(data);
					this.setState({ data: processedData }, () => {
						localStorage.setItem('cache', JSON.stringify({
							data: processedData,
							lastSaved: timestamp.getTime(),
						}));
					});
				});
		} else {
			this.setState({
				data: cache.data,
			});
		}
	}

	render() {
		const loading = (this.state.data === null);

		return (
			<Router basename={handleBasename()}>
				<div className="App container">
					<div className="row">
						<div className="col-sm">
							<Header />

							{ loading ? (
								<Loader />
							) : (
								<CredentialData.Provider value={this.state.data.credentials}>
									<TrackPageView>
										<Switch>
											<Route exact path="/" render={props => <Listing {...props} data={this.state.data.schools} />} />
											<Route exact path="/infographic" render={props => <InfoGraphic {...props} data={this.state.data.schools} />} />
											<Route exact path="/printable" render={props => <PrintableListing {...props} data={this.state.data.schools} />} />
											<Route path="/school/:id" render={props => <SchoolView {...props} data={this.state.data.schools} />} />
											<Route exact path="/videos" render={props => <VideoListing {...props} data={this.state.data.schools} />} />
										</Switch>
									</TrackPageView>
								</CredentialData.Provider>
							)}

							<Footer />
						</div>
					</div>
				</div>
			</Router>
		);
	}
}

export default App;
