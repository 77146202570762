import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import qs from 'qs'; // query string
import { Collapse, FormGroup, Input } from 'reactstrap';
import { SelectFilter } from './Filters.jsx';
import CardView from './CardView.jsx';
import MapView from './MapView.jsx';
import { uniqueValues } from '../lib/utils.js';
import { CredentialData } from './Credentials.jsx';

class Listing extends Component {
	constructor(props) {
		super(props);

		// get filter options from querystring
		const options = this.getOptions();

		// collapse filter initally only on mobile
		let filterOpenState = true;
		if (window.innerWidth < 769) {
			filterOpenState = false;
		}

		this.state = {
			search: '',
			filterOpen: filterOpenState,
			filter: options.filter || {},
			educationalModelOptions: [],
			schoolTypeOptions: [],
			authorizerOptions: [],
			credentialOptions: [],
		};

		this.filteredData = this.filteredData.bind(this);
		this.handleSearch = this.handleSearch.bind(this);
		this.handleFilterToggle = this.handleFilterToggle.bind(this);
		this.handleFilter = this.handleFilter.bind(this);
	}

	componentDidMount() {
		this.initFilterOptions();
	}

	handleSearch(e) {
		this.setState({
			search: e.target.value,
		});
	}

	handleFilterToggle(e) {
		this.setState({
			filterOpen: !this.state.filterOpen,
		});
	}

	handleFilter(e) {
		const { filter } = this.state;
		if (e.target.value !== '') {
			filter[e.target.name] = e.target.value;
		} else {
			// remove filter if not set
			delete filter[e.target.name];
		}

		this.setState({ filter }, () => this.applyFilter());
	}

	getOptions() {
		// get all options from query string
		return qs.parse(this.props.location.search, { ignoreQueryPrefix: true });
	}

	initFilterOptions() {
		const { data } = this.props;

		// get all the values from educationalModelOptions field, split fields that have CSVs
		const educationalModelOptions = uniqueValues(data, 'primaryEducationalModel');
		const schoolTypeOptions = uniqueValues(data, 'schoolType');
		const authorizerOptions = uniqueValues(data, 'authorizer');
		const cesaOptions = uniqueValues(data, 'cesa');
		const credentialOptions = uniqueValues(data, 'credentials').map(c => ({ value: c, label: this.context.find(x => x.shortCode === c).title }));

		this.setState({
			educationalModelOptions,
			schoolTypeOptions,
			authorizerOptions,
			cesaOptions,
			credentialOptions,
		});
	}

	applyFilter() {
		const options = this.getOptions();
		options.filter = this.state.filter;
		this.props.history.push(`?${qs.stringify(options)}`);
	}

	buildFilterOptions(optionsArr) {
		return optionsArr.map(o => <option key={o} value={o}>{o}</option>);
	}

	filteredData() {
		const { search, filter } = this.state;
		let filteredData = this.props.data;

		// first apply filter to data
		if (Object.keys(filter).length) {
			filteredData = filteredData.filter((s) => {
				let include = true;

				// filter keys map to fields on the school object
				// check each one to see if there is a match
				Object.keys(filter).forEach((field) => {
					if (typeof s[field] === 'number') {
						if (s[field] !== parseInt(filter[field], 10)) {
							include = false;
						}
					} else if (!s[field].includes(filter[field])) {
						include = false;
					}
				});

				return include;
			});
		}

		// then apply search to data
		if (search !== '') {
			return filteredData.filter(school => school.charterSchoolName.match(new RegExp(`.*${search}.*`, 'i')));
		}

		return filteredData;
	}

	renderSearch() {
		return (
			<FormGroup className="search-box">
				<FontAwesomeIcon icon="search" />
				<Input type="text" name="search" id="search" placeholder="Search" onChange={this.handleSearch} bsSize="sm" />
			</FormGroup>
		);
	}

	render() {
		// get and parse options from query string
		const options = this.getOptions();

		let ViewComponent;
		if (options.view === 'map') {
			ViewComponent = MapView;
		} else {
			ViewComponent = CardView;
		}

		const data = this.filteredData();

		return (
			<div className="row">
				<div className="listing-sidebar col-sm-3">
					<div>
						{this.renderSearch()}
					</div>
					<div className={`filter-group ${this.state.filterOpen ? 'open' : ''}`}>
						<div className="group-title" onClick={this.handleFilterToggle}>
							<FontAwesomeIcon icon={this.state.filterOpen ? 'angle-down' : 'angle-right'} />
							<FontAwesomeIcon icon="filter" />
							Filter
						</div>
						<Collapse isOpen={this.state.filterOpen}>
							<div className="group-body">
								<SelectFilter
									id="primaryEducationalModel"
									label="By Primary Educational Model"
									value={this.state.filter.primaryEducationalModel}
									onChange={this.handleFilter}
									options={this.state.educationalModelOptions}
								/>
								<SelectFilter
									id="schoolType"
									label="By School Type"
									value={this.state.filter.schoolType}
									onChange={this.handleFilter}
									options={this.state.schoolTypeOptions}
								/>
								<SelectFilter
									id="authorizer"
									label="By Authorizer"
									value={this.state.filter.authorizer}
									onChange={this.handleFilter}
									options={this.state.authorizerOptions}
								/>
								<SelectFilter
									id="cesa"
									label="By CESA"
									value={this.state.filter.cesa}
									onChange={this.handleFilter}
									options={this.state.cesaOptions}
								/>
								<SelectFilter
									id="peerSupportSchool"
									label="Peer Support School?"
									value={this.state.filter.peerSupportSchool}
									onChange={this.handleFilter}
									options={['Yes', 'No']}
								/>
								<SelectFilter
									id="subgranteeSchool"
									label="Charter School Program Sub-Grantee?"
									value={this.state.filter.subgranteeSchool}
									onChange={this.handleFilter}
									options={['Yes', 'No']}
								/>
								<SelectFilter
									id="inDevelopment"
									label="In Planning?"
									value={this.state.filter.inDevelopment}
									onChange={this.handleFilter}
									options={['Yes', 'No']}
								/>
								<SelectFilter
									id="credentials"
									label="Credentials"
									value={this.state.filter.credentials}
									onChange={this.handleFilter}
									options={this.state.credentialOptions}
								/>
							</div>
						</Collapse>
						<div className="text-center mt-3">
							Showing <strong>{data.length}</strong> schools
						</div>
					</div>
				</div>
				<div className="listing-body col-sm">
					<ViewComponent data={data} options={options} />
				</div>
			</div>
		);
	}
}
Listing.contextType = CredentialData;

Listing.propTypes = {
	location: PropTypes.object.isRequired,
	data: PropTypes.array.isRequired,
	history: PropTypes.object.isRequired,
};

export default withRouter(Listing);
