import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import EmbeddedVideo from './EmbeddedVideo.jsx';
import { schoolId } from '../lib/utils.js';

const VideoListing = ({ data }) => {
	// get all the videos
	const allTheVideos = data.reduce((videos, school) => {
		if (school.promotionalVideo !== '') {
			school.promotionalVideo.split(',').forEach((v) => {
				videos.push({
					charterSchoolName: school.charterSchoolName,
					url: v,
				});
			});
		}
		return videos;
	}, []);

	return (
		<div>
			<h2>Video Gallery</h2>
			<div className="video-grid mt-3">
				{allTheVideos.map(video => (
					<div className="text-center">
						<div className="mb-1">
							<Link to={`/school/${schoolId(video.charterSchoolName)}`}>
								<FontAwesomeIcon icon="school" />
								&nbsp;&nbsp;
								{video.charterSchoolName}
							</Link>
						</div>
						<EmbeddedVideo url={video.url} />
					</div>
				))}
			</div>
		</div>
	);
};

VideoListing.propTypes = {
	data: PropTypes.array.isRequired,
};

export default VideoListing;
