/* Utility Functions */

function camelize(str) {
	return str
		.replace(/(?:^\w|[A-Z]|\b\w)/g, (letter, index) => (index === 0 ? letter.toLowerCase() : letter.toUpperCase()))
		.replace(/\W+/g, '');
}

const schoolId = (school) => {
	let schoolName;
	if (typeof school === 'string') {
		schoolName = school;
	} else {
		schoolName = school.charterSchoolName;
	}

	return camelize(schoolName);
};

const getGovernanceList = (schoolName, data) =>
	data.governance.filter(item => item.charterSchoolName === schoolName);

const mergeGoveranceData = data => {
	const mergedSchoolData = data.schools.map(school => {
		const governanceList = getGovernanceList(school.charterSchoolName, data);
		if (governanceList.length > 0) {
			return Object.assign(school, { governanceList });
		}
		return school;
	});
	return Object.assign(data, { schools: JSON.parse(JSON.stringify(mergedSchoolData)) });
};

/* eslint-disable no-param-reassign */
const groupBy = (arr, key) =>
	arr.reduce((rv, x) => {
		(rv[x[key]] = rv[x[key]] || []).push(x);
		return rv;
	}, {});
/* eslint-disable no-param-reassign */

// given link text, make sure there is an http(s):// in front
// of it
const validUrl = (link) => {
	if (!link.match(/^https?:\/\//)) {
		return `http://${link}`;
	}

	return link;
};

const handleBasename = () => {
	if (window.location.host.includes('github')) {
		return '/wrccs-yearbook';
	}
	return '';
};

/* Given an array of objects and a fieldname, return a sorted list
 * of unique list of values found.  If a field contains a comma,
 * assume it is a CSV field and separate values accordingly */
const uniqueValues = (arr, fieldName) => {
	// get all the values from educationalModelOptions field, split fields that have CSVs
	let values = arr.map((s) => {
		if (typeof s[fieldName] === 'string') {
			return s[fieldName].split(/,\s*/);
		}
		return s[fieldName];
	});

	// now combine all split arrays into one big array
	// eslint-disable-next-line prefer-spread
	values = [].concat.apply([], values);

	// remove all duplicates and blanks
	values = [...new Set(values.filter(o => o !== ''))];

	// now sort options
	values = values.sort((a, b) => ((typeof a === 'number') ? a - b : a.localeCompare(b)));

	return values;
};

const uniqueValuesWithCounts = (arr, fieldName) => (
	Object.entries(arr.reduce((counts, s) => {
		s[fieldName].toString().split(',').forEach((model) => {
			const m = model.trim();

			/* eslint-disable no-param-reassign */
			if (!counts[m]) {
				counts[m] = 0;
			}

			counts[m] += 1;
		});
		return counts;
	}, {}))
);

export {
	schoolId,
	mergeGoveranceData,
	getGovernanceList,
	groupBy,
	validUrl,
	handleBasename,
	uniqueValues,
	uniqueValuesWithCounts,
};
