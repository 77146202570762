import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter, Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MediaQuery from 'react-responsive';
import { schoolId, validUrl } from '../lib/utils.js';
import { CredentialLongForm } from './Credentials.jsx';
import EmbeddedVideo from './EmbeddedVideo.jsx';

const AuthorizerInfo = ({ school }) => (
	<div className="authorizer-card">
		<div>Authorizer</div>
		<div className="title">{school.authorizer}</div>
		<ul>
			<li><FontAwesomeIcon icon={['far', 'address-book']} /><div>{school.authorizerContactName}</div></li>
			<li><FontAwesomeIcon icon="map-marker-alt" /><div>{school.authorizerAddress}</div></li>
			<li><FontAwesomeIcon icon="phone" /><div>{school.authorizerPhone}</div></li>
			{ school.authorizerEmail && (
				<li><FontAwesomeIcon icon={['far', 'envelope']} /><div>{school.authorizerEmail}</div></li>
			)}
			<li>
				<FontAwesomeIcon icon="globe" />
				<div>
					<a href={validUrl(school.authorizerWebsite)} target="authwebsite">Website</a>
				</div>
			</li>
		</ul>
		<div className="text-center mt-2">
			<FontAwesomeIcon icon="map" /> CESA {school.cesa}
		</div>
	</div>
);

AuthorizerInfo.propTypes = {
	school: PropTypes.object.isRequired,
};

const GovernanceList = ({ list }) => {
	if (!list || list.length === 0) {
		return null;
	}
	return (
		<table>
			<thead>
				<tr>
					<th>Name</th>
					<th>Title</th>
					<th>Email</th>
				</tr>
			</thead>
			{list.map(item => (
				<tr>
					<td>{`${item.boardMemberFirstName} ${item.boardMemberLastName}`}</td>
					<td>{item.boardMemberTitle}</td>
					<td>{item.boardMemberEmail || '--'}</td>
				</tr>
			))}
		</table>
	);
};

GovernanceList.propTypes = {
	list: PropTypes.array.isRequired,
};

const School = ({ school, hideAuthorizer, backlink }) => (
	<div className="school-listing">
		<div className="header">
			{ backlink && (
				<div className="back-link">
					<Link to={backlink}><FontAwesomeIcon icon="arrow-left" />&nbsp;&nbsp;Back to School Listing</Link>
				</div>
			)}
			<div className="row">
				<div className="col-sm-2 order-sm-2">
					<div className="school-est">
						{school.inDevelopment === 'Yes' ? 'Opening ' : 'Est. '}
						{school.yearEstablished}
					</div>
				</div>
				<div className="col-sm-10 order-sm-1">
					<h1>{school.charterSchoolName}</h1>
					<div className="school-address">
						<FontAwesomeIcon icon="map-marker-alt" />
						{school.schoolPhysicalAddress}
					</div>
				</div>
			</div>
		</div>
		<div className="row">
			<div className="col-sm-4 mb-3">
				<h2 className="mb-3">School Contact Info</h2>
				<div className="contact-info">
					<ul>
						{school.contact.split(',').map((c, i) => (
							[
								<li key={c}>
									<FontAwesomeIcon icon={['far', 'address-book']} /><div>{c}</div><br />
									<FontAwesomeIcon icon={['far', 'envelope']} /><div>{school.email.split(',')[i]}</div>
								</li>,
								<li key={`${c}-hr`}><hr /></li>,
							]))}
						<li><FontAwesomeIcon icon="phone" /><div>{school.phone}</div></li>
					</ul>
				</div>

				<MediaQuery minWidth={769}>
					{!hideAuthorizer && <AuthorizerInfo school={school} />}
				</MediaQuery>

				<div className="request-update">
					<a
						href="https://docs.google.com/forms/d/e/1FAIpQLSdY77Hchr_7UUiYfRW9tkblOXJmiDHyBqHZgNJktT8EOHwIeQ/viewform?usp=sf_link"
						className="btn btn-sm btn-secondary" target={`new${Math.round(Math.random() * 100000)}`}
					>
						<span><FontAwesomeIcon icon={['far', 'question-circle']} />&nbsp;</span>
						Request Update To This Listing
					</a>
				</div>
			</div>
			<div className="col-sm">
				<h2>About</h2>
				<div className="school-attributes">
					<div className="row">
						<div className="col col-sm mb-1">
							<span className="label">School Type:</span><br />
							{school.schoolType}
						</div>
						<div className="col col-sm mb-1">
							<span className="label">Grade Level:</span><br />
							{school.lowGrade} - {school.highGrade}
						</div>
						<div className="col col-sm">
							<span className="label">Educational Model:</span><br />
							{school.primaryEducationalModel}
						</div>
						{school.studentCount && (
							<div className="col col-sm">
								<span className="label">Students Served:</span><br />
								{school.studentCount}
							</div>
						)}
					</div>
				</div>
				<p>{school.schoolDescription}</p>

				{school.governanceList && (
					<div>
						<h2>Governance Board</h2>
						<GovernanceList list={school.governanceList} />
					</div>
				)}

				{school.promotionalVideo && (
					<>
						{school.promotionalVideo.split(',').map((url) => (
							<EmbeddedVideo url={url} />
						))}
					</>
				)}

				<div className="more-info">
					{school.peerSupportSchool === 'Yes' && (
						<p>
							<strong>{school.charterSchoolName}</strong> is a <strong>Peer Support School</strong> in the <a href="https://www.wrccs.org/mentor/" target="_backtowrccs">WRCCS Charter School Mentor Program</a>.
						</p>
					)}

					{school.subgranteeSchool === 'Yes' && (
						<p>
							<strong>{school.charterSchoolName}</strong> is a&nbsp;
							<strong><a target="_linetodpi" href="https://dpi.wi.gov/sms/charter-schools/grants">Wisconsin Charter School Program Sub-grantee</a></strong>.
						</p>
					)}

					{school.credentials && (
						<div>
							<p>{school.charterSchoolName} has received WRCCS Micro-credentials in:</p>
							<div className="credential-container">
								{school.credentials.split(',').map(c => <CredentialLongForm key={c.trim()} shortCode={c.trim()} dateAwarded={school.credentialsDateAwarded} />)}
							</div>
						</div>
					)}

					<br />
					<p className="emphasize">
						For more information, visit <FontAwesomeIcon icon="globe" />
						<a href={validUrl(school.website)} target="newpage">{school.website}</a>
					</p>
				</div>

				<MediaQuery maxWidth={769}>
					<div className="row">
						<div className="col text-center">
							{!hideAuthorizer && <AuthorizerInfo school={school} />}
						</div>
					</div>
				</MediaQuery>
			</div>
		</div>
	</div>
);

School.propTypes = {
	school: PropTypes.object.isRequired,
	hideAuthorizer: PropTypes.bool,
	backlink: PropTypes.string,
};

School.defaultProps = {
	hideAuthorizer: false,
	backlink: null,
};

class SchoolView extends Component {
	constructor(props) {
		super(props);
		this.state = {
			school: null,
		};
	}

	componentDidMount() {
		this.setSchool(this.props.match.params.id);
		window.scrollTo(0, 0);
	}

	setSchool(id) {
		// search for school based on id
		const school = this.props.data.find(o => schoolId(o) === id);

		// set instance varible for school
		this.setState({ school });
	}

	render() {
		if (!this.state.school) return (<div>Loading...</div>);
		const { data } = this.props;
		const { school } = this.state;
		const locationState = this.props.location.state;
		// const backLink = (locationState && locationState.backLink) ? locationState.backLink : '/';
		const backlink = window.localStorage.getItem('backlink');

		return <School school={school} backlink={backlink} />;
	}
}

SchoolView.propTypes = {
	match: PropTypes.object.isRequired,
	location: PropTypes.object.isRequired,
	data: PropTypes.oneOfType([
		PropTypes.object,
		PropTypes.array,
	]).isRequired,
};

export { School };
export default withRouter(SchoolView);
