import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from 'reactstrap';

const CredentialData = React.createContext();
const iconRootPath = '/images/';

const CredentialLongForm = ({ shortCode, dateAwarded }) => {
	const credentials = React.useContext(CredentialData);
	const cred = credentials.find(c => c.shortCode === shortCode);
	if (!cred) return null;

	const yearAwarded = new Date(dateAwarded).getFullYear();

	return (
		<div className="credential-card">
			<img alt={`Credential: ${cred.title}`} src={`${iconRootPath}/${shortCode}.svg`} />
			<div className="date-awarded text-center mb-1"><strong>Awarded {yearAwarded}</strong></div>
			<div>{cred.description}</div>
		</div>
	);
};

CredentialLongForm.propTypes = {
	shortCode: PropTypes.string.isRequired,
	dateAwarded: PropTypes.string,
};

CredentialLongForm.defaultProps = {
	dateAwarded: null,
};

const CredentialShortForm = ({ shortCode, id }) => {
	const [tooltipOpen, setTooltipOpen] = React.useState(false);
	const credentials = React.useContext(CredentialData);

	const toggle = () => setTooltipOpen(!tooltipOpen);
	const cred = credentials.find(c => c.shortCode === shortCode);
	if (!cred) return null;

	return (
		<div className="credential-icon-card">
			<span id={`${shortCode}${id}`}><img alt={`Credential: ${cred.title}`} src={`${iconRootPath}/${shortCode}IconOnly.svg`} /></span>
			<Tooltip placement="top" isOpen={tooltipOpen} target={`${shortCode}${id}`} toggle={toggle}>
				{cred.title}
			</Tooltip>
		</div>
	);
};

CredentialShortForm.propTypes = {
	shortCode: PropTypes.string.isRequired,
	id: PropTypes.string.isRequired,
};

export { CredentialData, CredentialLongForm, CredentialShortForm };
