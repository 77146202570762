import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { uniqueValues, uniqueValuesWithCounts } from '../lib/utils.js';
import { BarChart } from './Charts.jsx';

class InfoGraphic extends Component {
	educationalModelDataset() {
		const data = this.estSchools();
		const models = uniqueValuesWithCounts(data, 'primaryEducationalModel').filter(m => m[1] > 2);

		// sort models counts in descending order
		models.sort((a, b) => (b[1] - a[1]));

		// now put everything in ChartJS format
		const labels = models.map(m => m[0]); // array of string labels making modelCounts
		const modelCounts = models.map(m => m[1]); // array of integers

		return {
			labels,
			datasets: [{
				label: 'Educational Models',
				data: modelCounts,
			}],
		};
	}

	yearEstablishedDataset() {
		const data = this.estSchools();
		const years = uniqueValuesWithCounts(data, 'yearEstablished').filter(y => y[0] !== '');

		// sort models counts in ascending order
		years.sort((a, b) => (a[0].localeCompare(b[0])));

		// now put everything in ChartJS format
		const labels = years.map(m => m[0]); // array of string labels making modelCounts
		const yearCounts = years.map(m => m[1]); // array of integers

		return {
			labels,
			datasets: [{
				label: '# Schools Established',
				data: yearCounts,
			}],
		};
	}

	cesaDataset() {
		const data = this.estSchools();
		const cesa = uniqueValuesWithCounts(data, 'cesa');

		// sort models counts in ascending order
		cesa.sort((a, b) => (parseInt(a[0], 10) - parseInt(b[0], 10)));

		// now put everything in ChartJS format
		const labels = cesa.map(m => `CESA ${m[0]}`);
		const cesaCounts = cesa.map(m => m[1]);

		return {
			labels,
			datasets: [{
				label: 'CESA Counts',
				data: cesaCounts,
			}],
		};
	}

	// sum up all the students at each school
	studentCount() {
		const { data } = this.props;
		return data.reduce((sum, school) => (school.studentCount ? sum + school.studentCount : sum), 0);
	}

	estSchools() {
		// filter out schools that have not yet opened
		return this.props.data.filter(s => s.inDevelopment === 'No');
	}

	render() {
		const { data } = this.props;
		const authorizers = uniqueValues(data, 'authorizer').length;
		const studentCount = this.studentCount().toLocaleString('en');
		const peerSupportSchoolCount = data.filter(s => s.peerSupportSchool === 'Yes').length;
		const subgranteeSchoolCount = data.filter(s => s.subgranteeSchool === 'Yes').length;

		return (
			<div className="infographic">
				<div className="infographic-grid">
					<div className="info-cell wide var-height blue-bg">
						<div className="title">
							<span><FontAwesomeIcon icon="chart-area" /></span>
							&nbsp;By The Numbers
						</div>
					</div>
					<div className="info-cell light-blue-fg">
						<div>
							<div className="big-number">{studentCount}</div>
							<div className="big-number-label">Students</div>
						</div>
					</div>
					<div className="info-cell light-blue-bg">
						<div>
							<FontAwesomeIcon icon={['far', 'question-circle']} style={{ fontSize: '300%' }} /><br /><br />Wisconsin Charter Schools serve {studentCount} across the state.
						</div>
					</div>
					<div className="info-cell green-bg">
						<div>
							<FontAwesomeIcon icon={['far', 'question-circle']} style={{ fontSize: '300%' }} />
							<br /><br />
							WRCCS is here to serve {data.length} charter school in Wisconsin across 12 CESAs.
							<br /><br />
							<span style={{ fontSize: '140%' }}><strong>{peerSupportSchoolCount}</strong></span><br />serve as Peer Support Schools in the <a href="https://www.wrccs.org/mentor/mentor-schools.cfm" target="_backtowrccs">WRCCS Charter School Mentor Program</a>.
							<br /><br />
							<span style={{ fontSize: '140%' }}><strong>{subgranteeSchoolCount}</strong></span><br />are <a target="_linetodpi" href="https://dpi.wi.gov/sms/charter-schools/grants">WI Charter School Program Sub-grantees</a>.
						</div>
					</div>
					<div className="info-cell green-fg">
						<div>
							<div className="big-number">{this.estSchools().length}</div>
							<div className="big-number-label">Schools</div>
						</div>
					</div>
					<div className="info-cell">
						<div>
							<div className="chart-title">Educational Models</div>
							<div className="chart-container">
								<BarChart key={1} data={this.educationalModelDataset()} />
							</div>
						</div>
					</div>
					<div className="info-cell blue-bg">
						<div><FontAwesomeIcon icon={['far', 'question-circle']} style={{ fontSize: '300%' }} /><br /><br />Wisconsin Charters Schools cover a wide variety of learning models meeting the diverse needs of students across the state.</div>
					</div>
					<div className="info-cell light-blue-bg">
						<div>
							<FontAwesomeIcon icon={['far', 'question-circle']} style={{ fontSize: '300%' }} /><br /><br />Wisconsin authorizers are public school districts or other statutorily determined public institutions. Authorizing is the process of approving an application for a charter school, negotiating a contract, overseeing a school, and systematically deciding whether to renew or revoke its contract, based on mission and performance. 93% of authorizers in Wisconsin are school districts.
						</div>
					</div>
					<div className="info-cell light-blue-fg">
						<div>
							<div className="big-number">{authorizers}</div>
							<div className="big-number-label">Authorizers</div>
						</div>
					</div>
					<div className="info-cell">
						<div>
							<div className="chart-title">Schools Established By Year</div>
							<div className="chart-container">
								<BarChart key={2} data={this.yearEstablishedDataset()} vertical />
							</div>
						</div>
					</div>
					<div className="info-cell green-bg">
						<div><FontAwesomeIcon icon={['far', 'question-circle']} style={{ fontSize: '300%' }} /><br /><br />Charter Schools in Wisconsin are primarily launched through supporting federal or state grants. More than 80% of charter schools in WI have been in operation for more than 5 years.</div>
					</div>
					{/*					<div className="info-cell">
						<div>
								Charter Schools Supported by CESA
						</div>
					</div>
					<div className="info-cell">
						<div>
							<PieChart key={3} data={this.cesaDataset()} />
						</div>
					</div> */}
				</div>
			</div>
		);
	}
}

InfoGraphic.propTypes = {
	data: PropTypes.array.isRequired,
};

export default InfoGraphic;
