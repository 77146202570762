/* eslint-disable react/no-array-index-key */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter, Link } from 'react-router-dom';
import qs from 'qs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MediaQuery from 'react-responsive';
import { Card, CardHeader, CardFooter, CardBody, Button } from 'reactstrap';
import ViewNav from './ViewNav.jsx';
import { schoolId, groupBy } from '../lib/utils.js';
import ScrollManager from './ScrollManager.jsx';
import { CredentialShortForm } from './Credentials.jsx';

const backLinkFromLocation = (location) => {
	const pathName = location.pathname !== '/' ? location.pathname : '';
	return `${pathName}/${location.search}`;
};

const SchoolLink = ({ school, children, ...rest }) => {
	const saveBackLink = (link) => window.localStorage.setItem('backlink', link);
	const backlink = backLinkFromLocation(window.location);
	return (
		<a {...rest} title={school.charterSchooName} href={`/school/${schoolId(school)}`} onClick={e => saveBackLink(backlink)}>
			{children}
		</a>
	);
};

SchoolLink.propTypes = {
	school: PropTypes.object.isRequired,
	children: PropTypes.node.isRequired,
};

const SchoolCard = ({ school }) =>
	(
		<Card className="school">
			<CardHeader>
				{school.peerSupportSchool === 'Yes' && (
					<div className="school-label">
						Peer Support
					</div>
				)}
				<div className="school-title">
					<FontAwesomeIcon icon="school" />
					<SchoolLink school={school}>{school.charterSchoolName}</SchoolLink>
				</div>
				<div className="school-address">
					{school.inDevelopment === 'Yes' ? (
						`In Planning - Opening ${school.yearEstablished}`
					) :	(
						<span>
							<FontAwesomeIcon icon="map-marker-alt" />
							{school.schoolPhysicalAddress}
						</span>
					)}
				</div>
			</CardHeader>
			<CardBody>
				<div>
					<div className="contact-info">
						<ul>
							{school.contact.split(',').map((c, i) => (
								[
									<li key={`contact-${i}`}>
										<FontAwesomeIcon icon={['far', 'address-book']} />
										{c}
										<br />
										<FontAwesomeIcon icon={['far', 'envelope']} />
										{school.email.split(',')[i]}
									</li>,
								]))}
							<li>
								<FontAwesomeIcon icon="phone" />
								{school.phone}
							</li>
						</ul>
					</div>
					<br />
					<MediaQuery minWidth={769}>
						<div className="school-attributes">
							<p>
								<strong>School Type:</strong> {school.schoolType}
							</p>
							{school.primaryEducationalModel && (
								<p><strong>Educational Model:</strong> {school.primaryEducationalModel}</p>
							)}
						</div>
					</MediaQuery>
				</div>
			</CardBody>
			<CardFooter>
				<div className="row more">
					<div className="col my-auto">CESA {school.cesa}</div>
					<div className="col-6 text-center">
						{school.credentials.split(',').map(c => <CredentialShortForm key={c.trim()} id={schoolId(school)} shortCode={c.trim()} />)}
					</div>
					<div className="col my-auto text-right"><SchoolLink className="align-bottom" school={school}>View More...</SchoolLink></div>
				</div>
			</CardFooter>
		</Card>
	);

SchoolCard.propTypes = {
	school: PropTypes.object.isRequired,
};

const CESAHeader = ({ data }) => (
	<div className="card-listing-header">
		<div className="cesa-header">
			<FontAwesomeIcon icon="map" />CESA {data.cesa}
		</div>
	</div>
);

CESAHeader.propTypes = {
	data: PropTypes.object.isRequired,
};

const AuthorizerHeader = ({ data }) => (
	<div className="card-listing-header">
		<div className="authorizer-header">
			<div className="row">
				<div className="col col-sm-6">
					<h2>Authorizer:</h2>
					<h1>{data.authorizer}</h1>
					<div className="address">
						<FontAwesomeIcon icon="map-marker-alt" /><div>{data.authorizerAddress}</div>
					</div>
				</div>
				<MediaQuery minWidth={768}>
					<div className="col">
						<div className="row align-items-center h-100">
							<div className="col">
								<div className="text-center ">
									<div className="cesa"><FontAwesomeIcon icon="map" />CESA {data.cesa}</div>
								</div>
							</div>
							<div className="col">
								<div className="mr-2 contact-info">
									<ul>
										<li><FontAwesomeIcon icon={['far', 'address-book']} /><div>{data.authorizerContactName}</div></li>
										{data.authorizerEmail &&
											<li><FontAwesomeIcon icon={['far', 'envelope']} /><div>{data.authorizerEmail}</div></li>
										}
										<li><FontAwesomeIcon icon="phone" /><div>{data.authorizerPhone}</div></li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</MediaQuery>
			</div>
		</div>
	</div>
);

AuthorizerHeader.propTypes = {
	data: PropTypes.object.isRequired,
};

class CardView extends Component {
	constructor(props) {
		super(props);

		this.handleSort = this.handleSort.bind(this);
	}

	handleSort(e, fieldName) {
		const { options } = this.props;
		options.sortBy = fieldName;
		this.props.history.push(`?${qs.stringify(options)}`);
	}

	getSortOption() {
		return this.props.options.sortBy || 'charterSchoolName';
	}

	getSortedData() {
		const { data } = this.props;

		let sortBy = this.getSortOption();

		// check to make sure that sort by is a valid field
		if (data.length && !Object.keys(data[0]).includes(sortBy)) {
			// something is wrong.  Just sort by school name
			sortBy = 'charterSchoolName';
		}

		// first sort by school name
		let sortedData = data.sort((a, b) => a.charterSchoolName.localeCompare(b.charterSchoolName));

		// for cesa and authorizer, group by the field as well
		if (['cesa', 'authorizer'].includes(sortBy)) {
			sortedData = groupBy(sortedData, sortBy);
		}

		return sortedData;
	}

	renderSortLink(fieldName, label) {
		const isActive = this.getSortOption() === fieldName;
		return (
			<li className={isActive ? 'active' : ''}>
				<Button color="link" onClick={e => this.handleSort(e, fieldName)}>
					{label}
				</Button>
			</li>
		);
	}

	renderGroupedBy(data, sortBy) {
		// first add header
		const rendered = [];
		let GroupComponent;
		if (sortBy === 'cesa') {
			GroupComponent = CESAHeader;
		} else if (sortBy === 'authorizer') {
			GroupComponent = AuthorizerHeader;
		}

		// ensure keys are alphabetically sorted
		const keys = Object.keys(data).sort((a, b) => a.localeCompare(b, undefined, { numeric: true, sensitivity: 'base' }));

		keys.forEach((group) => {
			/* eslint-disable arrow-body-style */
			const schools = data[group].map((school, i) => {
				return <SchoolCard key={school.charterSchoolName} school={school} />;
			});
			/* eslint-enable arrow-body-style */

			// mash cesa and authorizer for unique key
			const key = `${data[group][0].cesa}-${data[group][0].authorizer}`;

			// first add header
			rendered.push([
				<div key={key}>
					<GroupComponent data={data[group][0]} />
					<div>
						{schools}
					</div>
				</div>,
			]);
		});

		return rendered;
	}

	render() {
		const data = this.getSortedData();

		return (
			<div>
				<div className="listing-nav">
					<div className="row">
						<div className="col sort">
							Sort by:
							<ul className="sort-links">
								{this.renderSortLink('charterSchoolName', 'School A-Z')}
								{this.renderSortLink('cesa', 'CESA')}
								{this.renderSortLink('authorizer', 'Authorizer')}
							</ul>
						</div>

						<div className="col view">
							<ViewNav />
						</div>
					</div>
				</div>
				<div className="">
					<ScrollManager scrollKey="card-listing" />
					{!(data instanceof Array) ? (
						this.renderGroupedBy(data, this.getSortOption())
					) : (
						data.map((school, i) => <SchoolCard key={school.charterSchoolName} school={school} />)
					)}
				</div>
			</div>
		);
	}
}

CardView.propTypes = {
	data: PropTypes.array.isRequired,
	history: PropTypes.object.isRequired,
	options: PropTypes.object,
};

CardView.defaultProps = {
	options: {},
};

export { AuthorizerHeader, SchoolLink };
export default withRouter(CardView);
