import React from 'react';
import PropTypes from 'prop-types';
import { Pie, Bar } from 'react-chartjs-2';

const barChartStyle = {
	backgroundColor: 'rgba(71,93,154,0.2)',
	borderColor: 'rgba(71,93,154,1)',
	borderWidth: 1,
	hoverBackgroundColor: 'rgba(71,93,154,0.4)',
	hoverBorderColor: 'rgba(71,93,154,1)',
};

const BarChart = ({ data, vertical }) => {
	// add default styling to datasets
	/* eslint-disable no-param-reassign */
	data.datasets = data.datasets.map(d => Object.assign({}, d, barChartStyle));

	const options = {
		responsive: true,
		maintainAspectRatio: false,
		scales: {
			xAxes: [{
				gridLines: {
					display: true,
				},
			}],
			yAxes: [{
				gridLines: {
					display: false,
				},
			}],
		},
		plugins: {
			legend: {
				display: false,
			},
		},
	};

	if (!vertical) {
		options.indexAxis = 'y';
	}

	return (
		<Bar
			data={data}
			options={options}
		/>
	);
};

BarChart.propTypes = {
	data: PropTypes.object.isRequired,
	vertical: PropTypes.bool,
};

BarChart.defaultProps = {
	vertical: false,
};

const PieChart = ({ data }) => (
	<Pie
		data={data}
		options={{
			legend: {
				display: false,
			},
			showDatasetLabels: true,
		}}
	/>
);

PieChart.propTypes = {
	data: PropTypes.object.isRequired,
};

export { BarChart, PieChart };
