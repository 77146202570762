/* eslint-disable object-property-newline */

import fontawesome from '@fortawesome/fontawesome';
import faCheckSquare from '@fortawesome/fontawesome-free-solid/faCheckSquare';
import faCoffee from '@fortawesome/fontawesome-free-solid/faCoffee';
import faPrint from '@fortawesome/fontawesome-free-solid/faPrint';
import faSchool from '@fortawesome/fontawesome-free-solid/faSchool';
import faMapMarkerAlt from '@fortawesome/fontawesome-free-solid/faMapMarkerAlt';
import faPhone from '@fortawesome/fontawesome-free-solid/faPhone';
import faEnvelope from '@fortawesome/fontawesome-free-regular/faEnvelope';
import faAddressBook from '@fortawesome/fontawesome-free-regular/faAddressBook';
import faGlobe from '@fortawesome/fontawesome-free-solid/faGlobe';
import faArrowLeft from '@fortawesome/fontawesome-free-solid/faArrowLeft';
import faHeart from '@fortawesome/fontawesome-free-solid/faHeart';
import faMap from '@fortawesome/fontawesome-free-solid/faMap';
import farMap from '@fortawesome/fontawesome-free-regular/faMap';
import faFilter from '@fortawesome/fontawesome-free-solid/faFilter';
import faSearch from '@fortawesome/fontawesome-free-solid/faSearch';
import faExchangeAlt from '@fortawesome/fontawesome-free-solid/faExchangeAlt';
import farStickyNote from '@fortawesome/fontawesome-free-regular/faStickyNote';
import faReply from '@fortawesome/fontawesome-free-solid/faReply';
import faAngleRight from '@fortawesome/fontawesome-free-solid/faAngleRight';
import faAngleDown from '@fortawesome/fontawesome-free-solid/faAngleDown';
import faQuestion from '@fortawesome/fontawesome-free-solid/faQuestion';
import faQuestionCircle from '@fortawesome/fontawesome-free-regular/faQuestionCircle';
import faChartArea from '@fortawesome/fontawesome-free-solid/faChartArea';
import faVideo from '@fortawesome/fontawesome-free-solid/faVideo';

// Set up font awesome
fontawesome.library.add({
	faCheckSquare, faCoffee, faPrint, faSchool, faMapMarkerAlt,
	faPhone, faEnvelope, faAddressBook, faGlobe, faArrowLeft,
	faHeart, faMap, faFilter, faSearch, faExchangeAlt, farStickyNote,
	farMap, faReply, faAngleRight, faAngleDown, faQuestion, faQuestionCircle,
	faChartArea, faVideo,
});
