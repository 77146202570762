import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MediaQuery from 'react-responsive';
import logo from '../logo.png';

const HeaderLink = ({ link, icon, label }) => (
	<div className="header-link">
		{/^https?:\/\//.test(link) ? (
			<a href={link}>
				<FontAwesomeIcon icon={icon} style={{ fontSize: '22px' }} /><br />
				{label}
			</a>
		) : (
			<Link to={link}>
				<FontAwesomeIcon icon={icon} style={{ fontSize: '22px' }} /><br />
				{label}
			</Link>
		)}
	</div>
);

HeaderLink.propTypes = {
	link: PropTypes.string.isRequired,
	icon: PropTypes.string.isRequired,
	label: PropTypes.string.isRequired,
};

const Header = () => (!window.location.pathname.includes('printable') ? (
	<div className="row App-header">
		<div className="col my-auto">
			<div className="logo-left">
				<Link to="/">
					<img src={logo} className="site-logo" alt="logo" />
				</Link>
			</div>
			<h1 className="App-title">Yearbook</h1>
		</div>
		<div className="col-4 text-right my-auto">
			<div className="header-links">
				<MediaQuery minWidth={768}>
					<HeaderLink icon="school" label="Schools" link="/" />
					<HeaderLink icon="chart-area" label="By The Numbers" link="/infographic" />
					<HeaderLink icon="video" label="Video Gallery" link="/videos" />
					<HeaderLink icon="print" label="Printable Version" link="/printable" />
				</MediaQuery>
				<HeaderLink icon="reply" label="Back to WRCCS" link="http://wrccs.org" />
			</div>
		</div>
	</div>
) : (
	<span />
));

export default Header;
