import React from 'react';

const Loader = () => (
	<div className="loader-fullpage">
		<div>
			<div className="lds-grid">
				<div /><div /><div /><div /><div /><div /><div /><div /><div />
			</div>
			<div className="loader-title">Loading...</div>
		</div>
	</div>
);

export default Loader;
