import React, { Component } from 'react';
import ReactGA from 'react-ga';
import { Route } from 'react-router-dom';
import PropTypes from 'prop-types';

const track = () => {
	ReactGA.pageview(window.location.pathname + window.location.search);
};

class TrackPageView extends Component {
	componentDidMount() { track(); }

	componentDidUpdate() { track(); }

	render() {
		return (
			<Route>
				{this.props.children}
			</Route>
		);
	}
}

TrackPageView.propTypes = {
	children: PropTypes.node.isRequired,
};

export default TrackPageView;
