import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Footer = () => (
	<div className="footer">
		<div className="footer-info">
			<strong>Wisconsin Resource Center for Charter School</strong><br />
			CESA 9<br />
			304 Kaphaem Rd<br />
			Tomahawk, WI 54487<br />
			Phone: 715-453-1079<br />
			shackett@wrccs.org<br />
		</div>

		This site was made with <FontAwesomeIcon icon="heart" /> by the team who brought you <a href="https://headrushlearning.com" target="hrlink">Headrush</a>.
	</div>
);

export default Footer;
