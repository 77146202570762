import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
	withScriptjs,
	withGoogleMap,
	GoogleMap,
	Marker,
	InfoWindow,
} from 'react-google-maps';
// import MarkerClusterer from 'react-google-maps/lib/components/addons/MarkerClusterer';
import ViewNav from './ViewNav.jsx';
import { SchoolLink } from './CardView.jsx';
import { schoolId, handleBasename } from '../lib/utils.js';

const API_KEY = 'AIzaSyCNQ1RLKYCeWcNfFLJ34DIGcuA61V_E8C8';

const MapComponent = withScriptjs(withGoogleMap(props => (
	<GoogleMap
		defaultZoom={7}
		defaultCenter={{ lat: 44.75, lng: -90.0 }}
	>
		{props.children}
	</GoogleMap>
)));

const SchoolInfoWindow = ({ school }) => (
	<div className="school-map-info-window">
		<div className="cesa">CESA {school.cesa}</div>
		<div className="title">
			<SchoolLink school={school}>{school.charterSchoolName}</SchoolLink>
		</div>
		<div className="school-address">{school.schoolPhysicalAddress}</div>
		<div className="school-attributes">
			<strong>School Type:</strong> {school.schoolType}<br />
			<strong>Educational Model:</strong> {school.primaryEducationalModel}<br />
		</div>
		<div className="school-map-info-window-footer">
			<SchoolLink school={school}>More...</SchoolLink>
		</div>
	</div>
);

SchoolInfoWindow.propTypes = {
	school: PropTypes.object.isRequired,
};

class SchoolMarker extends Component {
	constructor(props) {
		super(props);

		this.state = {
			isOpen: false,
		};

		this.toggleOpen = this.toggleOpen.bind(this);
	}

	getIcon() {
		const numSchools = this.props.location.schools.length;

		// if more than one school at this location, show cluster icon
		if (numSchools > 1) {
			// source for below is in public/map-pin-num.svg
			const svg = `
				<svg width="31px" height="47px" viewBox="0 0 31 47" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
						<title>map-pin</title>
					<g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
							<g id="map-pin">
									<path d="M15.5,0.4 C7.42340426,0.4 0.9,6.6 0.9,14.3 C0.9,21.9 15.5,47 15.5,47 C15.5,47 30.1,21.9 30.1,14.3 C30.1,6.6 23.5765957,0.4 15.5,0.4 Z" id="Path" fill="#475D9A"></path>
									<ellipse id="Oval" fill="#FFFFFF" fill-rule="nonzero" cx="15.5" cy="13.5" rx="11.5" ry="10.5"></ellipse>
									<text id="3" font-family="Lato-Bold, Lato, sanserif" font-size="19" font-weight="bold" fill="#475D9A">
											<tspan x="10" y="20">${numSchools}</tspan>
									</text>
							</g>
					</g>
			</svg>`;
			return ({
				url: `data:image/svg+xml;utf8,${encodeURIComponent(svg)}`,
				scaledSize: { width: 30, height: 30 },
			});
		}

		return {
			url: `${handleBasename()}/map-pin.svg`,
			scaledSize: { width: 30, height: 30 },
		};
	}

	toggleOpen() {
		this.setState({
			isOpen: !this.state.isOpen,
		});
	}

	render() {
		const { location } = this.props;
		const { isOpen } = this.state;

		const icon = this.getIcon();

		return (
			<Marker
				position={{ lat: location.latitude, lng: location.longitude }}
				onClick={this.toggleOpen}
				icon={icon}
			>
				{isOpen && (
					<InfoWindow onCloseClick={this.toggleOpen}>
						<div>
							{location.schools.map(s => <SchoolInfoWindow school={s} />)}
						</div>
					</InfoWindow>
				)}
			</Marker>
		);
	}
}

SchoolMarker.propTypes = {
	location: PropTypes.object.isRequired,
};

const groupByLoc = (data) => {
	// put all data in a hash according to their lat/lng
	const dataByLoc = {};
	data.forEach((s) => {
		const hash = `${s.latitude.toString()},${s.longitude.toString()}`;
		if (!dataByLoc[hash]) {
			dataByLoc[hash] = {
				latitude: s.latitude,
				longitude: s.longitude,
				schools: [],
			};
		}
		dataByLoc[hash].schools.push(s);
	});

	return Object.values(dataByLoc);
};

const MapView = ({ data, options }) => (
	<div>
		<div className="listing-nav">
			<div className="row">
				<div className="col view">
					<ViewNav />
				</div>
			</div>
		</div>
		<div className="map-view">
			<MapComponent
				isMarkerShown
				googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${API_KEY}&v=3.exp&libraries=geometry,drawing,places`}
				loadingElement={<div style={{ height: '100%' }} />}
				containerElement={<div style={{ height: '580px' }} />}
				mapElement={<div style={{ height: '100%' }} />}
			>
				{groupByLoc(data).map(location => (
					<SchoolMarker
						key={schoolId(location.schools[0])}
						location={location}
					/>
				))
				}
			</MapComponent>
		</div>
	</div>
);

MapView.propTypes = {
	data: PropTypes.array.isRequired,
	options: PropTypes.object,
};

MapView.defaultProps = {
	options: {},
};

export default MapView;
