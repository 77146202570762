import React, { Component } from 'react';
import PropTypes from 'prop-types';

const EmbeddedVideo = ({ url }) => {
	// figure out what kind of url we have
	let embedId;
	const ytUrl = new URL(url);
	if (ytUrl.origin.match(/^https:\/\/.*\.youtube.com/)) {
		embedId = ytUrl.searchParams.get('v');
	} else if (ytUrl.origin.match(/^https:\/\/youtu.be/)) {
		embedId = ytUrl.pathname.slice(1);
	} else {
		return (
			<div>Invalid YouTube URL: {url}</div>
		);
	}

	return (
		<div className="embedded-video mb-3">
			<iframe
				src={`https://www.youtube.com/embed/${embedId}`}
				frameBorder="0"
				allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
				allowFullScreen
				title="Embedded youtube"
			/>
		</div>
	);
};

EmbeddedVideo.propTypes = {
	url: PropTypes.string.isRequired,
};

export default EmbeddedVideo;
