import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup, Input, Label } from 'reactstrap';

// Build an options tag list from list of strings or objects
const buildFilterOptions = optionsArr => {
	// check first element, if it's an object, assuming array is of the format is
	// [{ value: "", label: ""}]
	if ((typeof optionsArr[0]) === 'object') {
		return optionsArr.map(o => <option key={o.value} value={o.value}>{o.label}</option>);
	}

	// otherwise assuming it's a simple array of strings
	return optionsArr.map(o => <option key={o} value={o}>{o}</option>);
};

const SelectFilter = ({
	label,
	id,
	value,
	onChange,
	options,
}) => (
	<FormGroup>
		<Label for={id}>{label}</Label>
		<Input
			type="select"
			name={id}
			id={id}
			bsSize="sm"
			className={value ? 'selected' : ''}
			onChange={onChange}
			value={value}
		>
			<option key="_" value="">--All--</option>
			{buildFilterOptions(options)}
		</Input>
	</FormGroup>
);

SelectFilter.propTypes = {
	label: PropTypes.string.isRequired,
	id: PropTypes.string.isRequired,
	onChange: PropTypes.func.isRequired,
	value: PropTypes.string,
	options: PropTypes.array,
};

SelectFilter.defaultProps = {
	value: '',
	options: [],
};

export { SelectFilter };
