import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import qs from 'qs';

class ViewNav extends Component {
	getOptions() {
		// get all options from query string
		return qs.parse(window.location.search, { ignoreQueryPrefix: true });
	}

	switchView(view) {
		const options = this.getOptions();
		options.view = view;
		this.props.history.push(`?${qs.stringify(options)}`);
	}

	render() {
		const options = this.getOptions();

		return (
			<div className="view-nav">
				<div className="view-label">View As:</div>
				<ul className="view-links">
					<li className={options.view !== 'map' ? 'active' : ''}>
						<button className="btn btn-link" onClick={() => this.switchView('card')}>
							<FontAwesomeIcon icon={['far', 'sticky-note']} /> Listing
						</button>
					</li>
					<li className={options.view === 'map' ? 'active' : ''}>
						<button className="btn btn-link" onClick={() => this.switchView('map')}>
							<FontAwesomeIcon icon={['far', 'map']} /> Map
						</button>
					</li>
				</ul>
			</div>
		);
	}
}

ViewNav.propTypes = {
	history: PropTypes.object.isRequired,
};

export default withRouter(ViewNav);
